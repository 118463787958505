<template>
  <AuthWrapper>
    <SignUpForm/>
  </AuthWrapper>
</template>

<script>
import AuthWrapper from 'common/components/auth/AuthWrapper';
import SignUpForm from 'common/components/auth/SignUpForm';

export default {
  name: 'SignUpPage',
  components: {
    SignUpForm,
    AuthWrapper,
  },
};
</script>
